/*position: relative;
left: 50%;
transform: translateX(-50%);*/

@font-face {
  font-family: 'OpenSansRegular';
  src: url('../fonts/OpenSansRegular/OpenSans-Regular.eot');
  src: url('../fonts/OpenSansRegular/OpenSans-Regular.eot') format('embedded-opentype'),
  url('../fonts/OpenSansRegular/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSansRegular/OpenSans-Regular.ttf') format('truetype'),
  url('../fonts/OpenSansRegular/OpenSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansLight';
  src: url('../fonts/OpenSansLigh/OpenSans-Light.eot');
  src: url('../fonts/OpenSansLigh/OpenSans-Light.eot') format('embedded-opentype'),
  url('../fonts/OpenSansLigh/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSansLigh/OpenSans-Light.ttf') format('truetype'),
  url('../fonts/OpenSansLigh/OpenSans-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansSemibold';
  src: url('../fonts/OpenSansSemibold/OpenSans-Semibold.eot');
  src: url('../fonts/OpenSansSemibold/OpenSans-Semibold.eot') format('embedded-opentype'),
  url('../fonts/OpenSansSemibold/OpenSans-Semibold.woff') format('woff'),
  url('../fonts/OpenSansSemibold/OpenSans-Semibold.ttf') format('truetype'),
  url('../fonts/OpenSansSemibold/OpenSans-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CirceRegular';
  src: url('../fonts/CirceRegular/Circe-Regular.eot');
  src: url('../fonts/CirceRegular/Circe-Regular.eot') format('embedded-opentype'),
  url('../fonts/CirceRegular/Circe-Regular.woff') format('woff'),
  url('../fonts/CirceRegular/Circe-Regular.ttf') format('truetype'),
  url('../fonts/CirceRegular/Circe-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CirceLight';
  src: url('../fonts/CirceLight/Circe-Light.eot');
  src: url('../fonts/CirceLight/Circe-Light.eot') format('embedded-opentype'),
  url('../fonts/CirceLight/Circe-Light.woff') format('woff'),
  url('../fonts/CirceLight/Circe-Light.ttf') format('truetype'),
  url('../fonts/CirceLight/Circe-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CirceExtraLight';
  src: url('../fonts/CirceExtraLight/Circe-ExtraLight.eot');
  src: url('../fonts/CirceExtraLight/Circe-ExtraLight.eot') format('embedded-opentype'),
  url('../fonts/CirceExtraLight/Circe-ExtraLight.woff') format('woff'),
  url('../fonts/CirceExtraLight/Circe-ExtraLight.ttf') format('truetype'),
  url('../fonts/CirceExtraLight/Circe-ExtraLight.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CirceBold';
  src: url('../fonts/CirceBold/Circe-Bold.eot');
  src: url('../fonts/CirceBold/Circe-Bold.eot') format('embedded-opentype'),
  url('../fonts/CirceBold/Circe-Bold.woff') format('woff'),
  url('../fonts/CirceBold/Circe-Bold.ttf') format('truetype'),
  url('../fonts/CirceBold/Circe-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'URWGeometricBold';
  src: url('../fonts/URWGeometricBold/URWGeometric-Bold.otf');
  src: url('../fonts/URWGeometricBold/URWGeometric-Bold.eot') format('embedded-opentype'),
  url('../fonts/URWGeometricBold/URWGeometric-Bold.woff') format('woff'),
  url('../fonts/URWGeometricBold/URWGeometric-Bold.ttf') format('truetype'),
  url('../fonts/URWGeometricBold/URWGeometric-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'URWGeometricExtraBold';
  src: url('../fonts/URWGeometricExtraBold/URWGeometric-ExtraBold.otf');
  src: url('../fonts/URWGeometricExtraBold/URWGeometric-ExtraBold.eot') format('embedded-opentype'),
  url('../fonts/URWGeometricExtraBold/URWGeometric-ExtraBold.woff') format('woff'),
  url('../fonts/URWGeometricExtraBold/URWGeometric-ExtraBold.ttf') format('truetype'),
  url('../fonts/URWGeometricExtraBold/URWGeometric-ExtraBold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-OpenSansRegular: 'OpenSansRegular', sans-serif;
$font-OpenSansLight: 'OpenSansLight', sans-serif;
$font-OpenSansSemibold: 'OpenSansSemibold', sans-serif;
$font-CirceRegular: 'CirceRegular', sans-serif;
$font-CirceLight: 'CirceLight', sans-serif;
$font-CirceExtraLight: 'CirceExtraLight', sans-serif;
$font-CirceBold: 'CirceBold', sans-serif;
$font-URWGeometricBold: 'URWGeometricBold', sans-serif;
$font-URWGeometricExtraBold: 'URWGeometricExtraBold', sans-serif;

body {
  font-family: $font-CirceBold;
  overflow-x: hidden;
  font-size: 14px;

}

* {
  outline: none;
  word-wrap: break-word;
}

a, a:focus, a:hover {
  text-decoration: none;
  transition: 0.3s ease-out;
}

ol, ul {
  list-style: none;
}

/*sprite*/

.sprite {
  background-image: url(../images/spritesheet.png);
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.sprite-arrow-thin-right {
  width: 16px;
  height: 12px;
  background-position: -5px -5px;
}

.sprite-facebook1 {
  width: 10px;
  height: 18px;
  background-position: -31px -5px;
}

.sprite-linkedin1 {
  width: 17px;
  height: 16px;
  background-position: -51px -5px;
}

.sprite-logo {
  width: 115px;
  height: 41px;
  background-position: -51px -31px;
}

.sprite-rectangle-5-copy-2 {
  width: 161px;
  height: 24px;
  background-position: -5px -82px;
}

.sprite-rectangle-5-copy-3 {
  width: 80px;
  height: 16px;
  background-position: -78px -5px;
}

.sprite-twitter1 {
  width: 18px;
  height: 14px;
  background-position: -168px -5px;
}

/*sprite*/

/*glitch*/
.container {
  position: relative;
}

.glitch-img {
  position: absolute;
  width: 100%;
  max-width: 90%;
}

/*glitch*/

.gmnoprint{
  display: none !important;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 320px) {
  .container {
    max-width: 270px;
  }
}

@media (min-width: 550px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 670px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1270px;
  }
}

/*modal-win*/

.buy-ticket-title {
  color: #000000;
  font-family: $font-CirceRegular;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -1.5px;
  text-align: center;
}

.buy-ticket-registration {
  width: 100%;
  max-width: 400px;
  margin: 1em auto;
}

.player-range {
  display: block;
  max-width: 620px;
  border: none;
  width: 100%;
  height: 4px;
  background-color: #000000;
}

input {
  width: 100%;
  padding: 0.5em 1em;
  margin: 0.5em auto;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #000000;
  font-family: $font-OpenSansRegular;
  font-size: 16px;
  line-height: 36px;
}

input::-webkit-input-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-family: $font-OpenSansRegular;
  font-size: 16px;
  line-height: 36px;
}

input:nth-child(1):focus {
  border-image-source: linear-gradient(to right, #4f00bc 0%, #29abe2 100%);
  border-image-slice: 1;
}

input:nth-child(3):focus {
  border-color: #000000;
}

input:nth-child(2):focus {
  border-color: #ff0000;
}

.buy-btn {
  display: block;
  margin: 1em auto;
  padding: 0 1em;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 60px;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #4f00bc 0%, #29abe2 100%);
  i {
    margin-left: 1em;
    vertical-align: unset;
  }
}

/*modal-win*/

/*RESPONSIVE-MENU*/

body.open_menu {
  height: 100%;
  overflow: hidden;
}

.menu-toggle {
  width: 55px;
  top: 0;
  left: 9px;
  padding: 5px 0;
  cursor: pointer;
  z-index: 99;
  position: absolute;
  display: none;
}

.menu-toggle.on .one {
  transform: rotate(45deg) translate(-5px, 1px);
}

.menu-toggle.on .two {
  opacity: 0;
}

.menu-toggle.on .three {
  transform: rotate(-45deg) translate(16px, -24px);
}

.one,
.two,
.three {
  width: 100%;
  height: 3px;
  background: #ffffff;
  backface-visibility: hidden;
  transition-duration: 0.3s;
  z-index: 99;
}

.two {
  margin: 10px auto;
}

.responsive-menu ul {
  padding: 0;
  list-style: none;
  margin: 6em auto;
  text-align: center;
}

.responsive-menu ul.hidden {
  display: none;
}

.responsive-menu ul a {
  transition-duration: 0.5s;
  text-decoration: none;
  color: white;
  font-size: 3em;
  line-height: 1.5;
  width: 100%;
  display: block;
}

.responsive-menu ul a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.responsive-menu ul li:nth-child(1n) {
  background-color: #181818;
  padding: 1em;
}

.responsive-menu ul li:nth-child(2n) {
  background-color: #131313;
  padding: 1em;
}

.responsive-menu ul li:nth-child(3n) {
  background-color: #0e0e0e;
  padding: 1em;
}

.menu-section.on {
  z-index: 10;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #000000;
  position: fixed;
  overflow: auto;
  animation: menu-position 1s;
}

/*RESPONSIVE-MENU*/

/*header*/

header {
  background-color: #000000;

}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.main-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/main-bg.png);
  padding: 1em 0 0 0;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
}

.menu-logo {
  width: 100%;
  position: relative;
  margin: 1em 0 1em 0;
  display: flex;
  flex: 0 0 25%;
}

.logo {
  display: inline-block;
}

.menu-container {
  width: 100%;
  margin: 1em 0 1em 0;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 66%;
}

.main-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  li {
    padding: 0 2.5em 2.5em 2em;
    display: inline-block;
    width: 17%;
    a {
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 1.4px;
    }
  }
}

.language {
  width: 100%;
  margin: 1em 0 1em 0;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 9%;
}

.eng, .rus {
  display: inline-block;
  padding: 0 0.2em;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
}

.active, .eng:hover, .rus:hover {
  background-image: linear-gradient(to right, #4f00bc 0%, #29abe2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-title-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.3em 0;
}

.main-title {
  margin: 0;
  margin-left: -10px;
  padding: 0.47em 0.2em 0.1em 0;
  color: #ffffff;
  font-size: 120px;
  line-height: 110px;
  letter-spacing: -3px;
}

.more-about {
  background-image: linear-gradient(to right, #4f00bc 0%, #29abe2 100%);
  color: #ffffff;
  line-height: 51px;
  text-transform: uppercase;
  letter-spacing: 0.55px;
  br {
    display: none;
  }
  i {
    margin-left: 1.8em;
    vertical-align: unset;
  }
}

.conference-details, .speakers-container, .price-container {
  display: flex;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  width: 100%;
  flex: 0 0 5%
}

.main-title-container {
  width: 100%;
  flex: 0 0 90%;
}

.where {
  color: #29abe2;
}

.when {
  color: #4f00bc;
}

.where, .when {
  line-height: 68px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

.where {
  padding: 0 0 3em 0;
}

.when {
  padding: 0 0 4.5em 0;
}

.meet-fintech-container {
  width: 100%;
  flex: 0 0 100%;
  padding: 10em 0 0.5em 0;
}

.meet-fintech {
  display: block;
  text-align: right;
  color: #ffffff;
  font-size: 34px;
  line-height: 96.11px;
  text-transform: uppercase;
  letter-spacing: 3.7px;
  position: relative;
  right: -5.5%;
  br {
    display: none;
  }
  i {
    margin-left: 1.7em;
    vertical-align: unset;
  }
}

.meet-fintech:hover {
  color: #ffffff;
}



.fintech-are-section {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.fintech-are {
  position: relative;
  margin-left: 29.5em;
  padding: 24.7em 0 5.2em 14.6em;
  background-color: #0000e6;
  background-image: linear-gradient(-197deg, #4f00bc 0%, #29abe2 100%);
  div {
    width: 100%;
    max-width: 95%;
  }
}

.quote {
  width: 100%;
  max-width: 85%;
  margin: 0;
  padding-left: 4.8em;
  position: absolute;
  left: -44%;
  top: 13%;
  background: url(../images/quote-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left top;
}

.quote-text {
  margin: 16px 0;
  color: #ffffff;
  font-family: $font-CirceRegular;
  font-size: 60px;
  line-height: 64px;
  letter-spacing: -1.6px;
}

.quote-author {
  color: #ffffff;
  text-transform: uppercase;
  line-height: 15px;
  font-family: $font-CirceExtraLight;
  span {
    font-family: $font-CirceBold;
    letter-spacing: 0.7px;
  }
}

.fintech-text, .fintech-text-join {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 38px;

}

.fintech-text {
  font-family: $font-OpenSansLight;
}

.fintech-text-join {
  font-family: $font-OpenSansSemibold;
  margin: 2em 0;
}

/*header*/


/*company-information*/

.company-information-section {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
}

.company-information {
  margin-top: -13em;
  z-index: 15;
  padding-top: 20em;
  background: #ffffff;
}


.about-tickets {
  padding-bottom: 18.5em;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% 36%;
  background-image: url(../images/bridge-bg.png);
}

.speakers-text, .price-text {
  margin: 0;
  color: #000000;
  font-size: 24px;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.speakers-text {
  padding: 3em 0 4.7em 0;
}

.price-text {
  padding: 0;
}

.speaker-employee {
  max-width: 20em;
}

.employee-photo {
  height: 100%;
  max-height: 270px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.employee-data {
  position: absolute;
  bottom: -10%;
  left: 0;
  z-index: 20;
}

.firstname, .lastname {
  width: fit-content;
  margin: 2px 0 0 0;
  padding: 5px 18px 0 19px;
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  line-height: 31px;
  letter-spacing: -0.75px;
}

.firstname {
  background-color: #4d08be;
}

.lastname {
  background-color: #2ba3e0;
}

.speakers-team-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 95%;
}

.employee-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 100%;
  padding: 4.5em 0 7em 0;
}

.speakers-team {
  display: inline-block;
  width: 100%;
  max-width: 335px;
  min-height: 256px;
  max-height: 245px;
  padding-left: 25px;
  margin: 2em 1em 4em 1em;
  font-size: 15px;
  position: relative;
}

.speakers-team-wrapper:hover .speakers-team-content {
  transform: translateY(0);
}

.speakers-team-wrapper:hover .speakers-team-content {
  opacity: 1;
}

.speakers-team-wrapper:hover .employee-photo {
  opacity: 0;
  transition: all .5s ease-in;
}

.speakers-team-wrapper:hover .employee-data {
  display: none;
}

.speakers-team-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 335px;
  min-height: 256px;
  max-height: 245px;
  background-color: #0000e6;
  background-image: linear-gradient(-197deg, #4f00bc 0%, #29abe2 100%);
  opacity: 0.902;
  transform: translateY(100%);
  transition: all .5s ease-in;
  z-index: 25;
}

.speakers-team-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  transform: translateY(0);
  opacity: 0;
  div:first-child {
    padding: 1em;
  }
}

.name-hover {
  margin: 0;
  color: #ffffff;
  font-size: 36px;
  line-height: 28px;
  letter-spacing: -0.9px;
}

.speakers-position {
  color: #ffffff;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

.speakers-team-text {
  opacity: 0.2;
  color: #ffffff;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -0.75px;
  margin: 0;
}

.employee-information {
  width: 100%;
  max-width: 17em;
  padding: 0.5em;
  margin: 1em 2em 1em 1em;
}

.employee-name {
  margin: 0;
  background-image: linear-gradient(-197deg, #4f00bc 0%, #29abe2 100%);
  color: #0000e6;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.6px;
}

.employee-position {
  margin: 0;
  opacity: 0.3;
  color: #000000;
  font-family: $font-OpenSansRegular;
  font-size: 16px;
  line-height: 25px;
}

.employee-company {
  margin: 0;
  color: #000000;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

.about-tickets-section {
  display: flex;
  flex-wrap: wrap;
}

.price-options-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 95%;
  flex-direction: column;
}

.price-options, .next-price-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  max-width: 65%;
}

.price-options {
  margin: 0 1em 1em 2.8em;
}

.next-price-options {
  margin: 0.5em 5.3em 1em auto;
}


.price-options {
  background-color: #000000;
  background-image: linear-gradient(-183deg, #4f00bc 0%, #29abe2 100%);
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 3em;
  }
}

.buy-now-till {
  margin: 9px;
  color: #ffffff;
  font-family: $font-CirceLight;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  span {
    font-family: $font-CirceBold;
    letter-spacing: 0.8px;
  }
}

.buy-now-price {
  margin: 9px;
  color: #ffffff;
  font-size: 80px;
  line-height: 100px;
  position: relative;
  top: 10px;
  sup {
    font-size: 48px;
  }
}

.buy-now {
  display: block;
  color: #ffffff;
  font-size: 16px;
  line-height: 60px;
  text-transform: uppercase;
  i {
    vertical-align: unset;
    margin-left: 1em;
  }
}

.buy-now:hover {
  color: #ffffff;
}

.next-price-options {
  background-color: #f6f6f6;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 4em;
  }
}

.next-when {
  margin: 0;
  color: #0b0b0b;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 2.52px;;
}

.next-price {
  margin: 9px;
  color: #0b0b0b;
  font-size: 60px;
  line-height: 80px;
  position: relative;
  top: 10px;
  sup {
    font-size: 39px;
  }
}

.next-buy {
  margin: 0;
  opacity: 0.5;
  color: #000000;
  line-height: 24px;
}

/*company-information*/



/*sponsors-and-partners*/

.media-partners-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sponsors-and-partners {
  background-color: #000000;
}

.media-container {
  display: flex;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  width: 100%;
  flex: 0 0 5%;
}

.sponsors-and-partners-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 100%;
}

.media-section {
  padding: 5em 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 43%;
}

.sponsors-and-partners-container {
  margin-top: -11.3em;
  padding: 8em 0 5em 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 57%;
  background-color: #0000e6;
  background-image: linear-gradient(-197deg, #4f00bc 0%, #29abe2 100%);
  z-index: 1;
  .company-logo {
    margin: 2em 1em;
  }
}

.sponsors-and-partners-text {
  margin: 0;
  padding-bottom: 1.4em;
  color: #ffffff;
  font-size: 21px;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: 3.2px;
}

.media {
  margin: 0;
  padding-bottom: 0.3em;
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  letter-spacing: 7.2px;
}

.media-companies, .sponsors-and-partners-companies {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
  max-height: 100%;
}

.sponsors-and-partners-companies {
  padding: 1em 1em 4em 1em;
  flex: 0 0 85%;
  .img-container {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    margin-bottom: 8em;
  }
}

.media-companies {
  padding: 1.5em 7em;
  flex: 0 0 57%;
  .img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 0 0 100%;
    margin-bottom: 3em;
  }
}

.img-container:last-child {
  margin-bottom: 0;
}


.big-company-logo {
  margin: 0 3.5em;
  width: 205px;
  height: 64px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.company-logo {
  margin: 0;
  width: 125px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.apply-for-accreditation {
  width: 100%;
  max-width: 100%;
  padding: 1.5em 0 0 7em;
  background-image: linear-gradient(-197deg, #4f00bc 0%, #29abe2 36%);
  color: #ffffff;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  br {
    display: none;
  }
  i {
    margin-left: 2.4em;
    vertical-align: sub;
  }
}

/*sponsors-and-partners*/

/*map*/

.map {
  background: #000000;
}

#map {
  margin-top: -8em;
  width: 100%;
  height: 730px;
}

#content {
  padding: 1em;
}

#firstHeading{
  background-image: linear-gradient(-197deg, #4f00bc 0%, #29abe2 100%);
  color: #0000e6;
  font-family: $font-CirceBold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.6px;
}

#bodyContent{
  p {
    margin: 0;
    opacity: 0.5;
    color: #000000;
    font-family: $font-OpenSansRegular;
    font-size: 16px;
    line-height: 24px;
  }
}

.gm-style-iw {
  background-color: #cecece;
  border: 1px solid #cecece;
  border-radius: 3px;
  color: rgb(255, 255, 255) !important;
  top: 15px !important;
}

/*map*/





/*footer*/

footer {
  background: #000000;
  color: #ffffff;
}

.first-part {
  padding: 1.5em 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.first-part-section, .second-part-section {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
}

.first-part-telephone {
  display: none;
}

.footer-logo-container,
.footer-about-us-menu,
.footer-sponsors-partners-menu,
.footer-agenda-menu,
.footer-book-now-menu,
.footer-social-icons-menu,
.second-part-telephone,
.second-part-office,
.second-part-conference-place,
.footer-copyright-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer-logo-container {
  flex: 0 0 18.6%;
}

.footer-about-us-menu {
  flex: 0 0 13.8%;
}

.footer-sponsors-partners-menu {
  flex: 0 0 20.8%;
}

.footer-agenda-menu {
  flex: 0 0 15.8%;
}

.footer-book-now-menu {
  flex: 0 0 15%;
}

.footer-social-icons-menu {
  flex: 0 0 16%;
}

.second-part-telephone {
  flex: 0 0 32.4%;
}

.second-part-office {
  flex: 0 0 20.8%;
}

.second-part-conference-place {
  flex: 0 0 30.8%;
}

.footer-copyright-container {
  flex: 0 0 16%;
  justify-content: flex-end;
}


.footer-logo {
  margin: 1em 0;
  display: inline-block;
}

.footer-menu {
  margin: 0.3em 0;
  padding: 0;
  a {
    color: #ffffff;
    font-family: $font-CirceRegular;
    line-height: 24.05px;
    letter-spacing: 0.7px;
  }

  li:first-child {
    a {
      font-family: $font-CirceBold;
      line-height: 30.06px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }
  }
}

.footer-social-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  a {
    display: inline-block;
    padding: 0.3em 1em 0 0.7em;
    color: #808080;
    font-size: 20px;
  }
}

.second-part {
  padding: 0.6em 0;
}

.phone-number, .email {
  color: #ffffff;
  font-family: $font-OpenSansRegular;
  line-height: 36px;
  margin: 0;
}

.address-title {
  margin: 0;
  padding: 0.6em 0;
  color: #ffffff;
  font-family: $font-OpenSansSemibold;
  line-height: 20px;
}

.address-text {
  margin: 0.3em 0;
  color: rgba(255, 255, 255, 0.5);
  font-family: $font-OpenSansRegular;
  line-height: 22px;
}


.copyright-text {
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.3);
  font-family: $font-OpenSansRegular;
  line-height: 25px;
  text-align: center;
}

/*footer*/






@media only screen and (max-width: 992px) {
  .logo {
    margin-left: 85px;
  }
  .menu-toggle {
    display: block;
  }
  .menu-container {
    display: none;
  }
  .menu-logo {
    max-width: 50%;
}
  .language {
    max-width: 40%;
  }
  .meet-fintech {
    width: fit-content;
    text-align: left;
    line-height: 50px;
    margin: 0 0 0 auto;
    br {
      display: inline;
    }
  }
  .fintech-are {
    margin-left: 15em;
    padding: 25em 2em 4em 4em;
  }
  .quote {
    max-width: 100%;
    left: -26%;
    top: 4%;
  }
  .speakers-team-container {
    flex: 0 0 88%;
  }
  .media-companies, .sponsors-and-partners-companies {
    flex: 0 0 75%;
  }
  .footer-logo-container, .footer-about-us-menu, .footer-sponsors-partners-menu, .footer-agenda-menu, .footer-book-now-menu {
    flex: 0 0 45%;
  }
  .first-part-telephone {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 0 0 45%;
  }
  .footer-social-icons-menu {
    flex: 0 0 100%;
  }
  .footer-social-icons {
    justify-content: center;
  }
  .second-part-telephone {
    display: none;
  }
  .second-part-office, .footer-copyright-container {
    flex: 0 0 30%;
  }
  .apply-for-accreditation {
    margin: 1em 0 1em 2em;
  }
  .apply-for-accreditation br {
    display: inline;
  }
}

@media only screen and (max-width: 768px) {
  .language {
    padding: 1em 3em 0 1em;
  }
  .eng, .rus {
    font-size: 24px;
  }
  .main-title-container {
    flex: 0 0 85%;
  }
  .main-title-section {
    padding-top: 12em;
    flex: 0 0 100%;
  }
  .main-title {
    letter-spacing: -3.49px;
    padding: 0.3em 0.2em 0 0;
  }
  .more-about.gradient-text {
    font-size: 24px;
    letter-spacing: 1.2px;
  }
    .conference-details, .speakers-container, .price-container {
    width: auto;
}
  .conference-details{
    padding-bottom: 3em;
  }

  .meet-fintech-container {
    padding: 8em 0 0.5em 0;
  }
  .meet-fintech {
    line-height: 40px;
  }
  .meet-fintech i {
    vertical-align: bottom;
  }
  .fintech-are {
    margin-left: 9em;
    padding: 16.4em 1em 4em 6em;
    margin-top: 4em;
  }
  .quote {
    padding-left: 3em;
    max-width: 100%;
    left: -18%;
    top: 5%;
  }
  .quote-text {
    font-size: 44px;
    line-height: 47.46px;
    letter-spacing: -2.25px;
  }
  .quote-author {
    margin: 1.5em 0 0 0;
  }
  .company-information {
    padding-top: 15em;
  }
  .employee-information {
    display: flex;
    flex-direction: column;
    flex: 0 0 43%;
  }
  .speakers-team {
    margin: 3em 1em;
  }
  .speakers-text {
    padding: 0 0 5em 0;
    letter-spacing: 4.27px;
  }
  .speakers-team, .speakers-team-content {
    max-width: 437px;
    min-height: 293px;
    max-height: 293px;
  }
  .employee-photo {
    height: 100%;
    max-height: 293px;
  }
/*  .employee-name.gradient-text {
    font-size: 34px;
    line-height: 48.57px;
    letter-spacing: -0.63px;
  }
  .employee-position {
    font-size: 22px;
    line-height: 48.57px;
  }
  .employee-company {
    font-size: 18px;
    line-height: 80.95px;
    letter-spacing: 1.33px;
  }*/
  .employee-container {
    margin-left: -6em;
    flex: 0 0 113%;
  }
  .price-text {
    letter-spacing: 4.27px;
  }
  .price-options, .next-price-options {
    max-width: 90%;
    padding: 2em 1em 1em 1em;
  }
  .next-price-options {
    margin: 0 1em 1em 1em;
  }
  .buy-now-till, .next-when {
    font-size: 26px;
  }
  .buy-now-price, .next-price {
    font-size: 50px;
  }
  .buy-now, .next-buy {
    font-size: 25px;
  }
  .about-tickets {
    padding-bottom: 3em;
  }
  .sponsors-and-partners-container {
    padding: 4em 0;
    margin-top: 0;
    flex: 0 0 95%;
  }
  .media-container {
    width: fit-content;
  }
  .big-company-logo {
    width: 155px;
    height: 49px;
  }
  .sponsors-and-partners-text {
    padding-bottom: 0;
    font-size: 27px;
  }
  .media-companies, .sponsors-and-partners-companies {
    padding: 2em;
  }
  .media-section {
    flex: 0 0 100%;
  }
  .media-container.media {
    display: none;
  }
  .media-section {
    padding: 4em 0;
  }
  .company-logo {
    width: 152px;
    height: 31px;
    margin: 1em 4em 6em 1em;
  }
  .media-companies, .sponsors-and-partners-companies {
    margin: 0 auto;
    justify-content: space-around;
  }
  .footer-social-icons {
    justify-content: space-around;
    a {
      font-size: 60px;
    }
  }
  .apply-for-accreditation br {
    display: none;
  }
  .apply-for-accreditation {
    font-size: 24px;
  }
  #map {
    height: 385px;
  }
  .phone-number, .email {
    font-size: 22px;
  }
  .footer-menu li:first-child a {
    font-size: 34px;
    color: #ffffff;
    line-height: 60px;
  }
  .footer-menu a {
    color: rgba(255, 255, 255, 0.5);
    line-height: 60px;
    font-size: 30px;
  }
  .second-part-office, .second-part-conference-place {
    flex: 0 0 45%;
  }
 .footer-copyright-container {
   flex: 0 0 100%;
   text-align: center;
}
  .address-title {
    font-size: 21px;
  }
  .address-text {
    font-size: 21px;
    line-height: 31.59px;
  }
  p.copyright-text {
    font-size: 18px;
    line-height: 41.91px;
  }
  .about-tickets {
    background: none;
  }
}

@media only screen and (max-width: 670px) {
  .main-bg {
    background: none;
  }
  .main-title-section {
    padding-top: 2em;
  }
  .main-title-container {
    flex: 0 0 100%;
  }
  .main-title {
    font-size: 60px;
    line-height: 65px;
    letter-spacing: -2.49px;
  }
  .more-about.gradient-text {
    font-size: 16px;
    letter-spacing: 1.2px;
  }
  .meet-fintech-container {
    padding: 4em 0 0.5em 0;
  }
  .meet-fintech {
    font-size: 20px;
  }
  .fintech-are {
    margin-left: 4em;
    padding: 12em 1em 2em 2em;
    margin-top: 1em;
  }
  .conference-details, .speakers-container, .price-container, .media-container {
    display: none;
  }
  .meet-fintech {
    font-size: 28px;
  }
  .quote {
    left: -10%;
    top: 3%;
  }
  .quote-text {
    font-size: 30px;
  }
  .speakers-team-container {
    flex: 0 0 100%;
  }
  .employee-container {
    margin-left: 0;
    flex: 0 0 100%;
    padding: 3em 0;
  }
  .price-options-section {
    flex: 0 0 100%;
  }
  .price-options, .next-price-options {
    margin: 0 0 1em 0;
    max-width: 95%;
  }
  .price-options {
    margin: 0 0 1em 0;
  }
  .next-price-options {
    margin: 0 0 1em 0;
  }
  .sponsors-and-partners-container {
    flex: 0 0 100%;
  }
  .company-logo {
    margin: 1em 1em 3em 1em;
  }
  .footer-logo-container, .footer-about-us-menu, .footer-sponsors-partners-menu, .footer-agenda-menu, .footer-book-now-menu {
    flex: 0 0 100%;
  }
  .first-part-telephone {
    flex: 0 0 100%;
  }
  .second-part-office, .second-part-conference-place {
    flex: 0 0 100%;
  }
}


@media only screen and (max-width: 550px) {
  .eng, .rus {
    font-size: 19px;
  }
  .more-about br {
    display: inline;
  }
  .main-title {
    padding: 0.3em 0.2em 0.5em 0;
  }
  .more-about {
    line-height: 30px;
  }
  .meet-fintech-container {
    padding: 2em 0 0.5em 0;
  }
  .fintech-are {
    margin-left: 0;
    padding: 1em;
    margin-top: 1em;
  }
  .quote {
    position: static;
    padding-left: 2em;
    max-width: 87%;
  }
  .quote-text {
    font-size: 20px;
    line-height: 27px;
  }
  .quote-author {
    margin: 1em 0 0 0;
  }
  .fintech-text, .fintech-text-join {
    line-height: 25px;
  }
  .speakers-team {
    margin: 3em 0;
  }
  .speakers-team, .speakers-team-content {
    max-width: 239px;
  }
  .employee-information {
    flex: 0 0 100%;
  }
  .next-buy br {
    display: none;
  }
  .apply-for-accreditation {
    font-size: 18px;
    line-height: 30px;
  }
  .apply-for-accreditation {
    margin: 1em 0 1em 0;
  }
  #map {
    margin-top: 0;
  }
}

@media only screen and (max-width: 320px) {


}